import Main from 'views/main';
import './App.css';

function App() {
  return (
    <div className="root">
      <Main />
    </div>
  );
}

export default App;
