import React from 'react';
import VideoPlayer from 'components/video-player';
import useFetch from 'hooks/use-fetch';

const Main = () => {
  const { loading, error, data, refetch } = useFetch(`hgPlaylistVideo/playlistVideos/9`);
  console.log('data:', data);

  return(
    <VideoPlayer videos={data.videos} />
  );
};

export default Main;