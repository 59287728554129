import React, { Component } from "react";
import * as THREE from "three";

class Sphere extends Component {
  async componentDidMount() {
    var parent = await document.getElementById(this.props.parentId);
    var width = await parent.clientWidth;
    var height = width / (16 / 9);

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize( width, height );
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.mount.appendChild( renderer.domElement );
    const video = document.getElementById( 'video' );
    const texture = new THREE.VideoTexture( video );
    var geometry = new THREE.SphereGeometry(3, 50, 50, 0, Math.PI * 2, 0, Math.PI * 2);
    var material = new THREE.MeshBasicMaterial( { map: texture } );
    var cube = new THREE.Mesh( geometry, material );
    scene.add( cube );
    camera.position.z = 5;
    var animate = function () {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    };
    animate();
  }
  render() {
    return (
      <>
        <video
          autoPlay
          muted
          id="video"
          src={this.props.video}
          width="1"
          height="1"
          loop
          style={{ visibility: 'hidden'}}
          crossOrigin="anonymous"
        />
        <div ref={ref => (this.mount = ref)} />
      </>
    )
  }
}

export default Sphere;