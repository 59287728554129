import React, { useState } from 'react';
import Cube from 'components/effects/cube';
import Sphere from 'components/effects/sphere';
import './styles.css';

const VideoPlayer = ({ videos }) => {
  const [videoSrc, setVideoSrc] = useState(0);

  const handleOnVideoEnd = () => {
    if(videoSrc < (videos?.length - 1)) setVideoSrc(videoSrc + 1)
    else setVideoSrc(0);
  };

  const renderPreview = () => {
    switch (videos[videoSrc].ffx) {
      case 'cube':
        return <Cube 
                  video={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_HOLOGRAM_VIDEO_DIR}${videos[videoSrc].filename}`}
                  parentId="video-container" />
      case 'sphere':
        return <Sphere
                  video={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_HOLOGRAM_VIDEO_DIR}${videos[videoSrc].filename}`}
                  parentId="video-container"/>
      default:
        return <Cube
                  video={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_HOLOGRAM_VIDEO_DIR}${videos[videoSrc].filename}`}
                  parentId="video-container"/>
    }
  };

  return(
    <div
      className="video-player-container"
    >
      {videos?.length > 0 &&
        <div id="video-container" className="video-container">
          <video
            autoPlay
            muted
            src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_HOLOGRAM_VIDEO_DIR}${videos[videoSrc].filename}`}
            
            onEnded={handleOnVideoEnd}
          />
          {renderPreview()}
        </div>
      }
    </div>
  );
};

export default VideoPlayer;